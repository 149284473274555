@import "/src/sassStyles/variables";

.contact {
  padding-bottom: 9rem;

  h2 {
    padding-bottom: 2rem;
  }

  .contactWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 5rem;

    &.dark {
      .form {
        color: $white;

        input,
        textarea {
          background-color: $pure-black;
        }

        input.submitBtn {
          color: $white;
          border: 2px solid $secondary-dark;
        }
      }
    }

    .threeExperience {
      height: 24rem;
      width: 30rem;
    }

    .line {
      border-left: 3px solid $secondary-dark;
      height: 500px;
    }

    .form {
      z-index: 100;

      form {
        label {
          display: block;
          margin-bottom: 1rem;
        }

        input,
        textarea {
          border: none;
          border-radius: 3px;
          color: $white;
          display: block;
          background-color: $primary-light;
          margin-top: 0.5rem;
          padding: 0.5rem 2.5rem 0.5rem 1.5rem;
          width: 300px;
        }

        .submitBtn {
          background-color: transparent;
          border: 2px solid $primary-light;
          color: $pure-black;
          box-shadow: inset rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
          transition: ease-out 0.3s;
          outline: none;
          cursor: pointer;
          font-family: "Roboto Mono", monospace;
          font-size: 1rem;
          overflow: hidden;
          position: relative;
          padding: 0.7rem;
          width: 300px;

          &:hover {
            color: $white;
            box-shadow: inset 300px 0 0 0 $secondary-dark;
          }
        }
      }
    }

    @media screen and (max-width: 990px) {
      flex-direction: column;
      gap: 3rem;

      .threeExperience {
        border: 3px solid $white;
      }

      .line {
        border-top: 3px solid #7ecbc7;
        border-left: none;
        height: 0;
        width: 75%;
      }

      .form {
        width: 65%;

        form {
          input,
          textarea {
            width: 100%;
          }

          .submitBtn {
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 675px) {
      .form {
        width: 85%;
      }
    }

    @media screen and (max-width: 500px) {
      .threeExperience {
        width: 85%;
      }
    }
  }

  @media screen and (max-width: 470px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
