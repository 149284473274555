@import "/src/sassStyles/variables";

.testimonials {
  overflow-x: hidden;
  padding: 2rem 0;
  text-align: center;
  background-image: url("../../assets/bluegradient.svg");
  background-size: cover;
  margin: 0 auto 7rem;
  max-width: 1200px;
  border-radius: 7px;

  &.dark {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.7) 100%
      ),
      url("../../assets/bluegradient.svg");

    h2 {
      color: $black;
    }

    .testimonialWrapper {
      background-color: $black;
      color: $white;
    }
  }

  h2 {
    color: $pure-black;
    margin-bottom: 4rem;
  }

  .testimonialWrapper {
    background-color: $white;
    border-radius: 10px;
    position: relative;
    max-width: 700px;
    margin: 0 auto;
  }

  .arrows {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 30px;
      pointer-events: none;
    }
    .next,
    .prev {
      margin: 0 0.5rem;
      width: fit-content;
      background-color: var(--deep-dark);
      padding: 0.5rem 2rem;
      border-radius: 8px;
      cursor: pointer;
      z-index: 100;

      img {
        width: 45px;
      }
    }
  }

  @media screen and (max-width: 950px) {
    .testimonialWrapper {
      width: 90%;
    }
  }
}
