@import "/src/sassStyles/variables";

.footer {
  background-color: $teal-lighten;
  max-width: unset;

  .footerContainer {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    width: 90%;
    height: 300px;

    .footerMain {
      background-color: rgba(255, 255, 255, 0.8);
      border: 3px solid $secondary-dark;
      display: flex;
      position: absolute;
      justify-content: space-between;
      padding: 3.5rem 3.5rem;
      width: 100%;
      top: -2.5rem;
      height: 100%;
      border-radius: 10px;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

      &.dark {
        background-color: rgba(0, 0, 0, 0.8);
        color: $white;
      }

      .logo {
        width: 150px;
      }

      .options {
        display: flex;
        gap: 1rem;
        margin-top: 6px;

        .pageOptions {
          margin-top: 0.7rem;
        }

        ul li {
          align-items: center;
          cursor: pointer;
          display: flex;
          font-size: 0.9rem;
          letter-spacing: 1.3px;
          margin-bottom: 1rem;

          &:hover {
            color: $secondary-dark;
          }

          .icon {
            align-items: center;
            background: $primary-light;
            border-radius: 50%;
            color: $white;
            display: flex;
            height: 40px;
            justify-content: center;
            margin-right: 10px;
            padding: 10px;
            width: 40px;
          }

          a {
            color: inherit;
          }
        }
      }
    }

    .copyright {
      font-size: 0.8rem;
      text-align: center;
    }
  }

  @media screen and (max-width: 700px) {
    .footerContainer {
      height: 500px;

      .footerMain {
        flex-direction: column;
        align-items: center;

        .options {
          gap: 3rem;

          ul {
            padding-left: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 450px) {
    .footerContainer {
      width: 100%;
    }
  }

  @media screen and (max-width: 400px) {
    .footerContainer {
      width: 100%;
      height: 625px;

      .options {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
      }
    }
  }
}
