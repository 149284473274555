@import "/src/sassStyles/variables";

.testimonialInfo {
  width: 100%;
  height: 450px;
  margin-bottom: 3rem;
  padding: 0rem 3rem 3rem;
  border-radius: 12px;

  &.dark .title {
    color: $secondary-dark;
  }

  .image {
    margin: 0 auto;
    position: relative;
    width: 80px;
    height: 72px;

    img {
      border-radius: 50%;
      height: 80px;
      position: absolute;
      top: -20px;
      width: 80px;
    }
  }

  .name {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 0;
  }

  .title {
    color: $primary-light;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .description {
    font-size: 1.125rem;
    text-align: justify;
  }

  @media screen and (max-width: 950px) {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }

  @media screen and (max-width: 720px) {
    height: 495px;
  }

  @media screen and (max-width: 550px) {
    height: 570px;
  }

  @media screen and (max-width: 475px) {
    .description {
      font-size: 1.1rem;
    }
  }

  @media screen and (max-width: 420px) {
    height: 630px;
  }

  @media screen and (max-width: 390px) {
    height: 720px;
  }
}
