@import "/src/sassStyles/variables";

h2 {
  color: $primary-light;
  font-family: "Roboto Mono", monospace;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;

  &.dark {
    color: $secondary-dark;
  }

  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
}
