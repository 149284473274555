@import "/src/sassStyles/variables";

.hero {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 120px;
  position: relative;

  .canvas {
    margin-top: 5rem;
    border: 3px solid $white;
    border-radius: 5px;
    height: 47rem;
    max-width: 1200px;
    pointer-events: none;
    padding-bottom: 0;
    width: 100%;

    .heroHeader {
      color: $white;
      font-family: "Roboto Mono", monospace;
      font-size: 2rem;
      width: 700px;
    }
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 4.5rem;
    padding-left: none;
    padding-top: 90px;
  }

  @media screen and (max-width: 625px) {
    .canvas {
      width: 95%;
    }
  }

  @media screen and (max-width: 515px) {
    margin-bottom: 10.5rem;
  }
}
