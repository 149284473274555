$primary-dark: #04005e;
$primary-light: #440bd4;
$secondary-dark: #7ecbc7;
// $secondary-light: #e92efb;
$pure-black: #262626;
$black: black;
$white: #ffff;

$teal-lighten: rgba($secondary-dark, 0.7);
$purple-lighten: rgba($primary-light, 0.2);
