@import "/src/sassStyles/variables";

.portfolio {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;

  &.dark {
    .portfolioFilter button {
      &:first-child {
        border-right: none;
      }

      &:last-child {
        border-left: none;
      }
    }

    .portfolioIntroText {
      color: $white;
    }
  }

  .portfolioIntroText {
    font-size: 1.1rem;
    line-height: 1.7;
    max-width: 855px;
    margin: 0 auto 2rem;
    text-align: justify;
  }

  .portfolioFilter {
    margin-bottom: 3.7rem;
    display: flex;
    justify-content: center;

    button {
      padding: 1rem 4rem;
      width: 310px;

      &:first-child {
        border-bottom-left-radius: 7px;
        border-top-left-radius: 7px;
        border-right: none;
      }

      &:last-child {
        border-bottom-right-radius: 7px;
        border-top-right-radius: 7px;
        border-left: none;
      }

      &:hover,
      &.focus {
        background: linear-gradient(
          45deg,
          rgba(127, 17, 224, 0.7),
          rgba(3, 252, 248, 0.7)
        );
        box-shadow: none;
        color: $white;
      }
    }
  }

  .portfolioItems {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }

  @media screen and (max-width: 725px) {
    &.dark {
      .portfolioFilter button {
        &:first-child {
          border-right: 2px solid $secondary-dark;
        }

        &:last-child {
          border-left: 2px solid $secondary-dark;
        }
      }
    }

    .portfolioFilter {
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;

      button {
        width: 75%;
        border-radius: 7px;

        &:first-child {
          border-right: 2px solid $primary-light;
        }

        &:last-child {
          border-left: 2px solid $primary-light;
        }
      }
    }
  }
}
