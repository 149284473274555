@import "./sassStyles/_reset.scss";
@import "./sassStyles/_global.scss";
@import "./sassStyles/_variables.scss";

body {
  #light {
    background-color: $white;
  }

  .dark {
    background-color: $black;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Poppins";
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

section {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 2rem 10rem;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

.webgl {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  outline: none;
  // z-index: 1;
}

#root,
#__next {
  isolation: isolate;
}

.react-switch-bg div {
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;

  .checkIcon {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  section {
    padding-bottom: 5rem;
  }
}
