ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

h1, h2, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}