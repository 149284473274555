@import "/src/sassStyles/variables";

.aboutContainer {
  padding-top: 13rem;

  .aboutWrapper {
    position: relative;
    margin: 0 auto;
    max-width: 1200px;
    min-height: 37rem;

    .about {
      background-color: rgba(255, 255, 255, 0.7);
      border: 3px solid $secondary-dark;
      display: flex;
      gap: 2rem;
      justify-content: center;
      padding: 3rem 2.2rem 3.2rem;
      z-index: 1;
      border-radius: 10px;
      width: 100%;

      &.dark {
        background-color: rgba(0, 0, 0, 0.6);
        .aboutText {
          h2,
          p {
            color: $white;

            span {
              color: $secondary-dark;
            }
          }

          hr {
            border-color: $primary-light;
          }
        }
      }

      .aboutPhoto {
        width: 370px;

        img {
          border-radius: 3px;
          border-bottom-left-radius: 8rem;
        }
      }

      .aboutText {
        max-width: 700px;

        h2,
        p {
          color: $black;
          text-align: left;

          span {
            color: $primary-light;
          }
        }

        hr {
          border: 3px solid $secondary-dark;
          margin-bottom: 1.5rem;
          margin-left: -5rem;
          width: 350px;
        }

        p {
          font-size: 1.125rem;
          margin-bottom: 1rem;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1040px) {
    padding-top: 3rem;

    .aboutWrapper {
      display: flex;
      justify-content: center;

      .about {
        align-items: center;
        flex-direction: column;

        .aboutPhoto {
          width: 200px;
        }

        .aboutText {
          margin-top: -5rem;

          p {
            font-size: 1.1rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .aboutWrapper .about {
      width: 100%;

      .aboutText hr {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 530px) {
    .aboutWrapper .about .aboutText hr {
      width: 230px;
    }
  }

  @media screen and (max-width: 470px) {
    padding-left: 0;
    padding-right: 0;

    .aboutWrapper .about {
      border: none;
      padding-bottom: 0;
    }
  }
}
