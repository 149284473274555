@import "/src/sassStyles/variables";

.skills {
  position: relative;
  margin: 0 auto;
  margin-bottom: 10rem;
  height: 475px;
  max-width: 1150px;
  width: 92%;

  &::before {
    background: $secondary-dark;
    border-radius: 10px;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    transform: translate(1.1rem, 1.1rem);
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .skillsContent {
    background-color: $primary-light;
    border-radius: 10px;
    color: $white;
    height: 475px;
    left: 0;
    margin: 0 auto;
    padding: 3rem;
    position: absolute;
    top: 0;
    z-index: 1;

    h2 {
      color: $secondary-dark;
    }

    &.dark {
      background-color: $pure-black;
      color: $white;
    }

    .skillText {
      font-size: 1.125rem;
      margin-bottom: 1.5rem;
      padding: 0 1rem;
    }

    .skillsList {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.13rem;
      gap: 2.1rem;
      margin: 0 auto;
      width: 85%;

      .skill {
        align-items: center;
        display: flex;
        gap: 0.6rem;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .skillsContent {
      padding: 1.1rem;

      .skillsList {
        width: 95%;
      }
    }
  }

  @media screen and (max-width: 810px) {
    height: 590px;

    .skillsContent {
      height: 590px;
    }
  }

  @media screen and (max-width: 610px) {
    height: 710px;

    .skillsContent {
      height: 710px;

      .skillsList {
        font-size: 1rem;
        width: 85%;
      }
    }
  }

  @media screen and (max-width: 490px) {
    height: 810px;

    .skillsContent {
      height: 810px;

      .skillText {
        font-size: 1.07rem;
      }
    }
  }

  @media screen and (max-width: 410px) {
    height: 950px;

    .skillsContent {
      height: 950px;
    }
  }

  @media screen and (max-width: 340px) {
    height: 1090px;

    .skillsContent {
      height: 1090px;
    }
  }
}
