@import "/src/sassStyles/variables";

.cardLink {
  min-height: 270px;
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  cursor: pointer;

  .cardHeader {
    font-size: 1.3rem;
  }

  img {
    border-radius: 10px;
    box-shadow: 0px 1px 2px 0px rgba(0, 255, 255, 0.3),
      1px 2px 4px 0px rgba(0, 255, 255, 0.3),
      2px 4px 8px 0px rgba(0, 255, 255, 0.3),
      2px 4px 16px 0px rgba(0, 255, 255, 0.2);
  }

  .projectLinks {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin: 1rem auto 0;

    button {
      border: 2px solid $secondary-dark;

      a {
        color: $white;
        display: flex;
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .projectLinks {
      align-items: center;
      flex-direction: column;
      gap: 0.7rem;
      width: 90%;

      button {
        width: 100%;
      }
    }
  }
}
